import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	Flex,
	Heading,
	Paragraph
} from 'reakit'

import { Container, Sidebar, Body, Footer } from '../../components/layouts'

import {
	CheckBox,
	Link
} from '../../components/ui'

import {
	refreshToken, updateTask, getTasks
} from '../../factory'

import Layout from '../../components/layout'

import theme from '../../theme/content'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
			update: false,
			timeout: true,
			checked: []
		}
		this.checkTask = this.checkTask.bind(this)
	}

	componentDidMount() {
		this.props.refreshToken().then(()=> {
		})
		this.props.getTasks(theme.pagesContent.onboarding.checklist)
	}

	checkTask(e) {
		this.props.updateTask(e.target.id, e.target.checked, this.props.token)
	}

	render() {
		return (
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body>
						<Heading as="h3">Onboarding To-Do's</Heading>
						<div style={{minHeight: 'calc(100vh - 200px)'}}>
							{theme.pagesContent.onboarding.checklist.map((item) => (
								<Flex row width="calc(100% + 2em)" alignItems={'center'} key={item.id}>
									<CheckBox
										onClick={item.link}
										id={item.id}
										name={`test_${item.title}`}
										value={item.completed}
										label={item.title}
										onChange={this.checkTask}
										style={{marginTop: 10, marginBottom: 10, minWidth: 100}}
									/>
									{item.link ?
										<Link href={item.link} target={'_blank'} style={{marginLeft: 10, fontFamily: 'work sans'}}>{item.text}</Link>
										:
										<Paragraph style={{marginLeft: 10, }}>{item.text}</Paragraph>
									}

								</Flex>
							))
							}
						</div>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
						<Flex />
					</Body>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	organization: PropTypes.object,
	token: PropTypes.string,
	pending: PropTypes.bool,
	refreshToken: PropTypes.func,
	getTasks: PropTypes.func,
	updateTask: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		token: state.authState.accessToken,
		pending: state.adminState.pending,
		onboarding: state.onboardingState
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		refreshToken: () => dispatch(refreshToken()),
		updateTask: (id, value, token)  => dispatch(updateTask(id, value, token)),
		getTasks: (taskList) => dispatch(getTasks(taskList))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
